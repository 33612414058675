<template>
    <b-container>
         <iq-card>
            <itemView :prop-data="itemsToView"/>
        </iq-card>
    </b-container>
</template>
<script>
import itemView from '@/components/structure/itemView'

export default {
    name: 'Details',
    props: ['fieldId'],
    components: { itemView },
    data () {
        return {
            details: {}
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.$t('globalTrans.name'),
                    value1: this.isBn ? this.details.name_bn : this.details.name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('globalUserData.email'),
                    value1: this.details.email,
                    title2: this.$t('cardPayment.nid'),
                    value2: this.details.nid
                },
                {
                    title1: this.$t('cardPayment.father_name'),
                    value1: this.isBn ? this.details.father_name_bn : this.details.father_name,
                    title2: this.$t('cardPayment.mother_name'),
                    value2: this.isBn ? this.details.mother_name_bn : this.details.mother_name
                }
            ]
            const addressFormatted = [
                {
                    title1: this.$t('farmerOperator.village'),
                    value1: this.isBn ? this.details.village_name_bn : this.details.village_name,
                    style: {
                        title1: 'max-width: 0'
                    }
                }
            ]
            const applicationFormatted = [
                {
                    title2: this.$t('irriPumpInformation.operator_status'),
                    value2: this.$t(this.details.status ? 'globalTrans.inactive' : 'globalTrans.active'),
                    lg: '6,10'
                }
            ]
            const message = [
                {
                    title1: this.$t('irriPumpInformation.reason'),
                    value1: this.isBn ? this.details.reason_bn : this.details.reason,
                    title2: this.$t('globalTrans.msg'),
                    value2: this.isBn ? this.details.message_bn : this.details.message
                }
            ]
            return {
                title: '',
                data:
                [
                    {
                        title: this.$t('globalTrans.personal_information'),
                        type: 'table',
                        data: personalFormatted
                    },
                    {
                        title: this.$t('globalTrans.address'),
                        type: 'table',
                        data: addressFormatted
                    },
                    {
                        title: this.$t('globalTrans.other_information'),
                        type: 'table',
                        data: message
                    },
                    {
                        title: this.$t('globalTrans.application_info'),
                        type: 'list',
                        data: applicationFormatted
                    }
                ]
            }
        }
    },
    created () {
        this.details = this.$store.state.list.find((item) => {
            if (item.id === this.fieldId) {
                return item
            }
        })
    }
}
</script>
