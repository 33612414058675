<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.operator_suspend') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="orgList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option value="" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
      <b-overlay :show="loadingState">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.operator_suspend_list') }}</h4>
          </template>
          <template v-slot:headerAction>
                <div v-if="dataExists">
                    <b-button class="btn_add_new mr-2" @click="pdfExport">
                      <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                    </b-button>
                    <export-excel
                        class="btn btn_add_new mr-2"
                        :data="dataArray"
                        title="Pump Operator Suspension Report"
                        worksheet="Pump Operator Suspension Report Sheet"
                        name="filename.xls">
                        <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                    </export-excel>
                    <export-excel
                      class="btn btn_add_new"
                      :data="dataArray"
                      worksheet="Pump Operator Suspension Report Sheet"
                      type="csv"
                      name="pump_operator_suspension.xls">
                      <i class="fas fa-file-download"></i>{{ $t('globalTrans.export_csv') }}
                    </export-excel>
                </div>
                <b-button class="btn_add_new ml-2" @click="currentComponent = 'CreateEdit';
                  modalSize = 'xl'" v-b-modal.modal-4 >
                  <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                </b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns">
                  <template v-slot:cell(name)="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:cell(index)="data">
                    {{ $n(data.index + 1) }}
                  </template>
                  <template v-slot:cell(pump_location)="data">
                    <b-button variant="primary"
                    v-if="data.item.info_latitude && data.item.info_longitude"
                    @click="mapModalOpen(data.item, 'info_latitude',
                    'info_longitude')"
                     v-b-modal.modal-4>
                        {{ $t('globalTrans.geoLocation') }}
                    </b-button>
                  </template>
                  <template v-slot:cell(operator_location)="data">
                    <b-button variant="primary" v-if="data.item.latitude && data.item.longitude"  @click="mapModalOpen(data.item, 'latitude',
                    'longitude')"
                     v-b-modal.modal-4>
                        {{ $t('globalTrans.geoLocation') }}
                    </b-button>
                  </template>
                  <template v-slot:cell(profile)="data">
                     <b-button variant="primary" @click="viewProfile(data.item.id)"
                     v-b-modal.modal-4>
                        {{ $t('globalTrans.view') }}
                    </b-button>
                  </template>
                  <template v-slot:cell(suspension_created_at)="data">
                    <span :title="data.item.suspension_created_at | dateFormat">{{ data.item.suspension_created_at | dateFromNow }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge">{{ getStatus(data.item) }}</span>
                  </template>
                </b-table>
                  <b-pagination
                      align="center"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total-rows="pagination.totalRows"
                      @input="searchData()"
                  />
              </b-col>
            </b-row>
          </template>
        </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal id="modal-4" :size="modalSize" :title="modalTitle" :hide-footer=true>
        <component :is="currentComponent" :latitude="latitude" :longitude="longitude" :fieldId="fieldId"/>
    </b-modal>
  </b-container>
</template>
<script>
import CreateEdit from './CreateEdit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptSuspensionGetPumpList, pumpOptSuspensionList } from '../../api/routes'
import FormSearch from '@/components/structure/form/search/FormSearch'
import { List } from '@/mixins/helper-functions'
import MapModal from './MapModal'
import Details from './Details'
import Vue from 'vue'
import excel from 'vue-excel-export'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
Vue.use(excel)

export default {
  mixins: [List, ModalBaseMasterList],
  name: 'PumpOptlist',
  components: {
    MapModal, CreateEdit, Details, ListReportHead, FormSearch
  },
  data () {
    return {
        dataExists: false,
        fieldId: '',
        currentComponent: '',
        modalSize: 'xl',
        modalTitle: 'Operator Suspension Form',
        search: {
            org_id: ''
        },
        rows: [],
        dataList: {
            org_id: '',
            division_id: '',
            district_id: '',
            upazilla_id: '',
            union_id: '',
            pump_id: ''
        },
        districtList: [],
        upazilaList: [],
        unionList: [],
        pumpList: [],
        operatorList: [],
        dataArray: [],
        pumpDependenciesSelected: false,
        latitude: 0,
        longitude: 0
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.pump_id'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.pump_operator_id'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.operator_name'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.pump_location'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.operator_location'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.profile'), class: 'text-left' },
            { label: this.$t('pump_install.created_date'), class: 'text-left' },
            { label: this.$t('irriPumpInformation.operator_status'), class: 'text-center' },
            { label: this.$t('complain.notel'), class: 'text-center' }
        ]

        let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'pumpid' },
          { key: 'pump_operator_username' },
          { key: 'name_bn' },
          { key: 'pump_location' },
          { key: 'operator_location' },
          { key: 'profile' },
          { key: 'suspension_created_at' },
          { key: 'status' },
          { key: 'message_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'pumpid' },
          { key: 'pump_operator_username' },
          { key: 'name' },
          { key: 'pump_location' },
          { key: 'operator_location' },
          { key: 'profile' },
          { key: 'suspension_created_at' },
          { key: 'status' },
          { key: 'message' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    searchAttributes () {
        return {
            buttonText: this.$t('globalTrans.search'),
            data: [
                {
                    name: 'org_id',
                    type: 'Select',
                    label: 'globalTrans.organization',
                    options: this.orgList,
                    selectTitle: this.$t('globalTrans.select'),
                    labelCol: 3,
                    lg: 6
                }
            ]
        }
    }/* ,
    listData () {
        return this.$store.state.IrriPumpInoformation.pumpOperatorSuspensions
    } */
  },
  watch: {
    'dataList.org_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.checkPumpDependencies()
            this.operatorList = this.getOperatorList(newVal)
        }
    },
    'dataList.division_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.districtList = this.getDistrictList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.district_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.upazilla_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.union_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.checkPumpDependencies()
        }
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    pdfExport () {
        if (!this.search.org_id) {
            alert('Please Select An Organization')
            return
        }
        const rowData = this.getPdfData()
        const labels = [
            { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('globalTrans.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('irriPumpInformation.pumpid'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('irriPumpInformation.pump_operator_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('irriPumpInformation.operator_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('irriPumpInformation.pump_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('irriPumpInformation.operator_location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
            { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
        rowData.unshift(labels)
        const reportTitle = this.$i18n.locale === 'en' ? 'Pump Operator Report' : 'পাম্প অপারেটর রিপোর্ট'
        const columnWids = ['8%', '20%', '10%', '20%', '15%', '20%', '*']
        ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name_bn' },
          { key: 'pumpid' },
          { key: 'pump_operator_username' },
          { key: 'name_bn' },
          { key: 'pump_id' },
          { key: 'village_name_bn' },
          { key: 'status_text' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'org_name' },
          { key: 'pumpid' },
          { key: 'pump_operator_username' },
          { key: 'name' },
          { key: 'pump_id' },
          { key: 'village_name' },
          { key: 'status_text' }
        ]
      }
      var serial = 0
      const listData = this.dataArray.map(item => {
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    getStatus (item) {
        const statusList = this.$store.state.commonObj.activeIntactive
        var status = statusList.find(el => el.value === item.status)
        if (this.$i18n.locale === 'bn') {
            return status.name_bn
        } else {
            return status.name
        }
      },
    mapModalOpen (item, latKey, longKey) {
        this.modalTitle = this.$t('irriPumpInformation.mapTitle')
        this.modalSize = 'lg'
        this.currentComponent = 'MapModal'

        for (const [key, value] of Object.entries(item)) {
            if (latKey === key) {
                this.latitude = value
            }
            if (longKey === key) {
                this.longitude = value
            }
        }
      },
    viewProfile (id) {
        this.modalTitle = this.$t('globalTrans.details')
        this.modalSize = 'lg'
        this.fieldId = id
        this.currentComponent = 'Details'
      },
    checkPumpDependencies () {
        const filled = ['org_id', 'division_id', 'district_id', 'upazilla_id', 'union_id']
        let flag = true
        filled.forEach(el => {
            if (this.dataList[el].length === 0) {
                flag = false
            }
        })
        if (flag) {
           this.getPumpList()
        }
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        try {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

            RestApi.getData(irriSchemeServiceBaseUrl, pumpOptSuspensionList, params).then(response => {
                if (response.success) {
                    const organizationList = this.$store.state.commonObj.organizationProfileList
                    response.data.data.map((item) => {
                        const orgObject = organizationList.find(org => org.value === item.org_id)
                        item.org_name = orgObject.text
                        item.org_name_bn = orgObject.text_bn
                        item.status_text = this.getStatus(item)
                    })
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                    this.dataArray = response.data
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
            })
        } catch (e) {
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            if (e) {
              //
            }
        }
    },
    async getPumpList () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        await RestApi.getData(irriSchemeServiceBaseUrl,
            pumpOptSuspensionGetPumpList, {
                org_id: this.dataList.org_id,
                division_id: this.dataList.division_id,
                district_id: this.dataList.district_id,
                upazilla_id: this.dataList.upazilla_id,
                union_id: this.dataList.union_id
            }
        ).then((result) => {
            if (result.data.length > 0) {
                const pumpList = result.data.map(el => {
                    return { text: el.pump_id, value: el.id }
                })

                if (Array.isArray(pumpList)) {
                    this.pumpList = pumpList
                }
            } else {
                this.pumpList = []
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }).catch(() => {
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
    },
    getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

        if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
    }
  }
}
</script>
