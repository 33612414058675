<template>
<p>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading.form">
            <iq-card>
                <template v-slot:body>
                    <form-input :data="formInputData" @change="setData" @submit="submitData"/>
                </template>
            </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</p>
</template>

<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import {
    pumpOptSuspensionGetPumpList,
    pumpOptSuspensionGetOperatorList,
    pumpOptSuspensionStore
  } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput.vue'
import { List } from '@/mixins/helper-functions'

export default {
  mixins: [List],
  name: 'PumpOptSuspensionForm',
  components: {
      FormInput
  },
  data () {
    return {
        operator_id: '',
        dataList: {
            org_id: '',
            division_id: '',
            district_id: '',
            upazilla_id: '',
            union_id: '',
            mouza_id: '',
            pump_id: ''
        },
        districtList: [],
        upazilaList: [],
        unionList: [],
        mouzaList: [],
        pumpList: [],
        operatorList: [],
        pumpDependenciesSelected: false,
        loading: {
            form: false,
            pump_id: {
                placeholder: '',
                readOnly: false
            },
            operator_id: {
                placeholder: '',
                readOnly: false
            }
        }
    }
  },
  computed: {
     divisionList () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList
    },
    formInputData () {
        return {
            chunk: true,
            buttonLocale: this.operator_id ? 'globalTrans.update' : 'irriPumpInformation.operator_suspend',
            buttonClass: 'btn btn-primary float-right custom-margin-right',
            buttonContainerClass: 'justify-content-end',
            dataParent: this.dataList,
            data: [
                {
                    head: {
                        title: ''
                    },
                    items: [
                        {
                            name: 'org_id',
                            type: 'Select',
                            label: 'globalTrans.organization',
                            options: this.orgList,
                            selectTitle: this.$t('globalTrans.select'),
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'division_id',
                            label: 'globalTrans.division',
                            type: 'Select',
                            selectTitle: this.$t('globalTrans.select'),
                            options: this.divisionList,
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'district_id',
                            type: 'Select',
                            selectTitle: this.$t('globalTrans.select'),
                            label: 'globalTrans.district',
                            options: this.districtList,
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'upazilla_id',
                            type: 'Select',
                            selectTitle: this.$t('globalTrans.select'),
                            label: 'globalTrans.upazila',
                            options: this.upazilaList,
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'union_id',
                            type: 'Select',
                            selectTitle: this.$t('globalTrans.select'),
                            label: 'globalTrans.union',
                            options: this.unionList,
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'mouza_id',
                            rules: 'nullable',
                            type: 'Select',
                            selectTitle: this.$t('globalTrans.select'),
                            label: 'irrigation_config.mouza',
                            options: this.mouzaList,
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'pump_id',
                            type: 'Select',
                            label: 'irriPumpInformation.pump_id',
                            options: this.pumpList,
                            labelCol: 5,
                            lg: 6,
                            placeholder: this.loading.pump_id.placeholder,
                            readOnly: this.loading.pump_id.readOnly
                        },
                        {
                            name: 'operator_id',
                            type: 'Select',
                            label: 'irriPumpInformation.pump_operator_id',
                            options: this.operatorList,
                            labelCol: 5,
                            lg: 6,
                            placeholder: this.loading.operator_id.placeholder,
                            readOnly: this.loading.operator_id.readOnly
                        },
                        {
                            name: 'suspend_date',
                            type: 'Datepicker',
                            label: 'irriPumpInformation.suspension_date',
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'message',
                            type: 'Textarea',
                            label: 'irriPumpInformation.suspension_messagef',
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'message_bn',
                            type: 'Textarea',
                            label: 'irriPumpInformation.suspension_message_bn',
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'reason',
                            type: 'Textarea',
                            label: 'irriPumpInformation.suspension_reasonf',
                            labelCol: 5,
                            lg: 6
                        },
                        {
                            name: 'reason_bn',
                            type: 'Textarea',
                            label: 'irriPumpInformation.suspension_reason_bn',
                            labelCol: 5,
                            lg: 6
                        }
                    ]
                }

            ]
        }
    }
  },
  watch: { // far_pump_opt_apps
    'dataList.org_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.checkPumpDependencies()
            this.operatorList = this.getOperatorList(newVal)
        }
    },
    'dataList.division_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.districtList = this.getDistrictList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.district_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.upazilla_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.union_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.mouzaList = this.getMouzaList(newVal)
            this.checkPumpDependencies()
        }
    },
    'dataList.mouza_id': function (newVal, oldVal) {
        this.checkPumpDependencies()
    },
    'dataList.pump_id' (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getPumpOptList()
        }
    }
  },
  methods: {
    async getPumpOptList () {
        this.loading.operator_id.placeholder = 'Loading...'
        this.loading.operator_id.readOnly = true

        await RestApi.getData(irriSchemeServiceBaseUrl,
            pumpOptSuspensionGetOperatorList, {
                pump_id: this.dataList.pump_id
            }
        ).then((result) => {
            if (result.data.length > 0) {
                const operatorList = result.data.map(el => {
                    return { text: this.$i18n.locale === 'bn' ? el.name_bn : el.name, value: el.id }
                })

                if (Array.isArray(operatorList)) {
                    this.operatorList = operatorList
                }
            } else {
                this.operatorList = []
            }

            this.loading.operator_id.placeholder = ''
            this.loading.operator_id.readOnly = false
        })
        .catch(() => {
            this.loading.operator_id.placeholder = ''
            this.loading.operator_id.readOnly = false
        })
    },
    async submitData () {
        this.loading.form = true

        let result = null

        result = await RestApi.postData(irriSchemeServiceBaseUrl,
        pumpOptSuspensionStore, this.dataList)

      if (result.success) {
        // push notification
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)

        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })

      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
        this.loading.form = false
    },
    getOperatorList () {

    },
    checkPumpDependencies () {
        const filled = ['org_id', 'division_id', 'district_id', 'upazilla_id', 'union_id']
        let flag = true
        filled.forEach(el => {
            if (this.dataList[el].length === 0) {
                flag = false
            }
        })
        if (flag) {
            this.getPumpList()
        }

        if (this.dataList.pump_id) {
            this.operatorList = []
        }
    },
    setData (data) {
        this.dataList = Object.assign(this.dataList, data)
    },
    async getPumpList () {
        this.loading.pump_id.placeholder = 'Loading...'
        this.loading.pump_id.readOnly = true

        await RestApi.getData(irriSchemeServiceBaseUrl,
            pumpOptSuspensionGetPumpList, {
                org_id: this.dataList.org_id,
                division_id: this.dataList.division_id,
                district_id: this.dataList.district_id,
                upazilla_id: this.dataList.upazilla_id,
                union_id: this.dataList.union_id,
                mouza_id: this.dataList.mouza_id
            }
        ).then((result) => {
            if (result.data.length > 0) {
                const pumpList = result.data.map(el => {
                    // return { text: el.pump_id, value: el.id }
                    return { text: el.pump_id, value: el.id }
                })

                if (Array.isArray(pumpList)) {
                    this.pumpList = pumpList
                }
            } else {
                this.pumpList = []
            }

            this.loading.pump_id.placeholder = ''
            this.loading.pump_id.readOnly = false
        })
        .catch(() => {
            this.loading.pump_id.placeholder = ''
            this.loading.pump_id.readOnly = false
        })
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }
        return upazilaList
    },
    getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

        if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === unionId)
      }
      return mouzaList
    }
  },
  mounted () {
      core.index()
  }
}
</script>

<style>
    .custom-margin-right {
        margin-right: -20px !important;
    }
</style>
